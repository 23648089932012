.collat-name{
  margin-top: 12px;
  padding-right: 6px;
}

.mt-3{margin-top: 3em;}


.energyFree{
    margin-left: 40px;
    margin-top: -35px;
}

.home-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #2f3349;
}
.home-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-hero {
color: #c5c5c5!important;
    background: #17121d73!important;
    box-shadow: 0px 2px 2px #109e77;
}
.home-max-width {
  align-items: stretch;
}
.home-content-container {
  flex: 1;
  width: 100%;
  display: flex;
  min-width: 50%;
  align-items: flex-start;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text {
  width: 90%;
  font-size: 52px;
  margin-bottom: 42px;
}
.home-text02 {
  color: #109e77;
}
.home-features-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-feature {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}
.home-icon {
  fill: #109e77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.home-text04 {
  color: var(--dl-color-grays-gray60);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-feature1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}
.home-icon2 {
  fill: #62BF77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.home-text05 {
  color: var(--dl-color-grays-gray60);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-feature2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon4 {
  fill: #62BF77;
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-right: 12px;
}
.home-text06 {
  color: var(--dl-color-grays-gray60);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-input-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-button {
  margin: 10px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  line-height: 1;
  padding-top: 21px;
  white-space: nowrap;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 21px;
  justify-content: center;
  background-color: #7a45a5!important;
  color: #fff!important;
  border: none;
}

.home-button1 {
  margin: 10px;
  display: flex;
  align-items: center;
  line-height: 1;
  padding-top: 20px;
  white-space: nowrap;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 20px;
  justify-content: center;
  background-color: #2f3349;
}
.home-image-container img {
    width: 100%;
    height: 100%;
/*    padding-left: 10%;*/
    margin-left: 2%;
    margin-top: -30px;
    box-shadow: 0px 0px 20px 10px #7a45a5b3;
    border-radius: 30px;
  }
.home-image-container {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image {
  width: 447px;
  max-width: 589px;
  object-fit: cover;
  margin-left: 45px;
}
.home-stats {
  width: 100%;
  /*display: grid;*/
  display: none;
  padding: var(--dl-space-space-threeunits);
  grid-gap: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  grid-template-columns: 1fr 1fr 1fr;
}

  .wallet-ux img, .sorrel-gostables {
    max-width: 120%;
    margin-left: 0px;
    box-shadow: none!important;
  }

.home-stat {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text07 {
  color: rgb(73, 197, 182);
  font-size: 2rem;
}
.home-text08 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.home-stat1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text09 {
  color: rgb(73, 197, 182);
  font-size: 2rem;
}
.home-text10 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.home-stat2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text11 {
  color: rgb(73, 197, 182);
  font-size: 2rem;
}
.home-text12 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.home-section-one {
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.home-max-width1 {
  flex-direction: column;
}
.home-text13 {
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: Lexend;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
.home-text14 {
  color: #109e77;
}
.home-container01 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container02 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container03 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container04 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container05 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-image01 {
  width: 50px;
  margin: 10px;
  object-fit: cover;
}
.home-image02 {
  width: 50px;
  margin: 10px;
  object-fit: cover;
}
.home-image03 {
  width: 50px;
  margin: 10px;
  object-fit: cover;
}
.home-image04 {
  width: 50px;
  margin: 10px;
  object-fit: cover;
}
.home-image05 {
  width: 50px;
  margin: 10px;
  object-fit: cover;
}
.home-image06 {
  width: 50px;
  margin: 10px;
  object-fit: cover;
}
.home-image07 {
  width: 50px;
  margin: 10px;
  object-fit: cover;
}
.home-max-width2 {
  flex-direction: row;
  justify-content: flex-start;
}
.home-image-container1 {
  flex: 1;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.home-image08 {
  width: 96%;
  max-width: 640px;
  object-fit: cover;
/*  margin-left: -50px;*/
/*      -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0.8)));*/
/*      mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0.8));*/
    box-shadow: 0px 0px 33px 6px #109e7696;
    border-radius: 22px;
}
.home-content-container1 {
  flex: 1;
  display: flex;
  max-width: 446px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text19 {
  font-size: 42px;
  font-style: normal;
  font-family: Lexend;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 24px;
  text-transform: none;
  text-decoration: none;
}
.home-text23 {
  color: #109e77;
  font-size: 42px;
  font-style: normal;
  font-family: Lexend;
  font-weight: 600;
  line-height: 1.3;
  text-transform: none;
  text-decoration: none;
}
.home-text24 {
  color: #109e77;
}
.home-text25 {
  color: var(--dl-color-grays-gray60);
  font-style: normal;
  font-weight: 400;
}
.home-text33 {
  font-weight: 700;
}
.home-text37 {
  font-weight: 700;
}
.home-text38 {
  font-weight: 700;
}
.home-max-width3 {
  flex-direction: row;
  justify-content: flex-start;
}
.home-content-container2 {
  flex: 1;
  display: flex;
  position: relative;
  max-width: 446px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text40 {
  font-size: 42px;
  font-style: normal;
  font-family: Lexend;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 24px;
  text-transform: none;
  text-decoration: none;
}
.home-text41 {
  color: #a761d1;
}
.home-container06 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container08 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container09 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-text43 {
  color: var(--dl-color-grays-gray60);
}

.home-text43 b{
  color:#a761d1;
}
.green, .home-text43 b span{
  color:#109e77!important;
}


.home-container10 {
  margin: 0px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image09 {
  width: 30px;
  margin: 5px;
  object-fit: cover;
  margin-right: 5px;
}

.usdd-image-x{
  width: 36px;
  display: inline;
  margin-bottom: -2px;
}

.home-image10 {
  width: 30px;
  margin: 5px;
  object-fit: cover;
}
.home-image11 {
  width: 30px;
  margin: 5px;
  object-fit: cover;
}
.home-image12 {
  width: 30px;
  margin: 5px;
  object-fit: cover;
}
.home-image13 {
  width: 30px;
  margin: 5px;
  object-fit: cover;
}
.home-text48 {
  color: var(--dl-color-grays-gray60);
}
.home-link {
  text-decoration: underline;
}
.home-image-container2 {
  flex: 1;
  margin-left: var(--dl-space-space-oneandhalfunits);
}

.home-image-container2 svg {
  width: 380px;
  float: right;
  border-radius: 50px;
  padding: 20px;
  box-shadow: 0px 3px 25px #a761d1;;
}

.home-image14 {
  width: 90%;
  object-fit: cover;
  margin-left: 60px;
}
.home-max-width4 {
  flex-direction: row;
  justify-content: flex-start;
}
.home-image-container3 {
  flex: 1;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.home-image15 {
  width: 70%;
  max-width: 640px;
  object-fit: cover;
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}
.home-container11 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-content-container3 {
  flex: 1;
  display: flex;
  max-width: 446px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text55 {
  font-size: 42px;
  font-style: normal;
  font-family: Lexend;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 24px;
  text-transform: none;
  text-decoration: none;
}
.home-text59 {
  color: #a761d1;
  font-size: 42px;
  font-style: normal;
  font-family: Lexend;
  font-weight: 600;
  line-height: 1.3;
  text-transform: none;
  text-decoration: none;
}
.home-text60 {
  color: #a761d1;
}
.home-text61 {
  color: var(--dl-color-grays-gray60);
  font-style: normal;
  font-weight: 400;
}
.home-text66 {
  font-weight: 700;
}
.home-text70 {
  font-weight: 700;
}
.home-text75 {
  font-weight: 700;
}
.home-text79 {
  font-weight: 700;
}
.home-button2 {
  color: rgb(255, 255, 255);
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgb(73, 197, 182);
}
.home-button2:hover {
  transform: scale(1.02);
}
.home-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.home-text84 {
  color: rgb(33, 33, 33);
  font-size: 3rem;
  align-self: center;
  text-align: center;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-text85 {
  color: #a761d1;
}
.home-text87 {
  color: #212121;
}
.home-container12 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.home-text88 {
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.home-text89 {
  color: #109e77;
}
.home-text90 {
  color: #a761d1;
}
.home-section-four {
  padding-bottom: 0px;
}
.home-max-width5 {
  flex-direction: column;
}
.home-text91 {
  font-size: 42px;
  text-align: center;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-text93 {
  color: #a761d1;
}
.home-slider {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  transition: 0.5s;
  align-items: stretch;
  flex-direction: row;
  box-shadow: 0px 0px 12px 12px #2424356e;
}
.home-section-five {
  align-items: center;
  justify-content: center;
}
.home-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-text94 {
  font-size: 3rem;
  text-align: center;
}
.home-text95 {
  color: #a761d1;
}

.w-180 img{
      width: 180px!important;
}

.pt-27{
      padding-top: 27px!important;
}

.column {
  float: left;
  padding: 12px;
}

/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
}


.home-text97 img{width: 200px;}

.home-text97 {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-button3 {
  color: rgb(255, 255, 255);
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgb(73, 197, 182);
}
.home-button3:hover {
  transform: scale(1.02);
}
.home-button4 {
  color: rgb(255, 255, 255);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgb(255, 148, 82);
}
.home-button4:hover {
  transform: scale(1.02);
}
.home-button5 {
  color: rgb(255, 255, 255);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #FF9452;
}
.home-button5:hover {
  transform: scale(1.02);
}
.home-button6 {
  color: rgb(255, 255, 255);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #FF9452;
}
.home-button6:hover {
  transform: scale(1.02);
}
@media(max-width: 991px) {
  .home-max-width {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-content-container {
    width: 100%;
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text {
    width: 100%;
  }
  .home-image {
    max-width: 100%;
    margin-left: 0px;
  }
  .home-text07 {
    text-align: center;
  }
  .home-text09 {
    text-align: center;
  }
  .home-text11 {
    text-align: center;
  }
  .home-image08 {
    max-width: 100%;
    margin-left: 0px;
  }
  .home-content-container1 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text19 {
    width: 100%;
  }
  .home-content-container2 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text40 {
    width: 100%;
  }
  .home-image14 {
    max-width: 100%;
    margin-left: 0px;
  }
  .home-image15 {
    max-width: 100%;
    margin-left: 0px;
  }

  .home-content-container3 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text55 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-content-container {
    width: 100%;
  }
  .home-stats {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    grid-template-columns: 1fr 1fr;
  }
  .home-text08 {
    text-align: center;
  }
  .home-text10 {
    text-align: center;
  }
  .home-text12 {
    text-align: center;
  }
  .home-text13 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-max-width2 {
    flex-direction: column;
  }
  .home-image-container1 {
    width: 100%;
    margin-right: 0px;
  }
  .home-image08 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-content-container1 {
    max-width: 100%;
  }
  .home-max-width3 {
    flex-direction: column-reverse;
  }
  .home-content-container2 {
    max-width: 100%;
  }
  .home-image-container2 {
    width: 100%;
    margin-right: 0px;
  }
  .home-image14 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-max-width4 {
    flex-direction: column;
  }
  .home-image-container3 {
    width: 100%;
    margin-right: 0px;
  }
  .home-image15 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-content-container3 {
    max-width: 100%;
  }
  .home-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container12 {
    grid-template-columns: 1fr;
  }
  .home-text91 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text97 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-content-container {
    width: 100%;
  }
  .home-text {
    width: 100%;
  }
  .home-input-container {
    
  }
  .home-stats {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
    grid-template-columns: 1fr;
  }
  .home-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-button4 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .home-button5 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .home-button6 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}

